import { Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AbstractComponent, MessageScheduledService, MessageScheduled, Pager, Attendance, ConfirmationComponent, MessageHelper, MessageTypeEnum, Message, MessageService, UtilHelper } from 'lib-trend-core';
import { delay, takeUntil } from 'rxjs/operators';
import { MessageScheduledStatusEnum } from 'lib-trend-core';
import { BehaviorSubject } from 'rxjs';
import { ChatMessageScheduleComponent } from '../chat-message-schedule';

@Component({
  selector: 'message-schedule-list',
  templateUrl: './chat-message-schedule-list.html',
  styleUrls: ['./chat-message-schedule-list.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatMessageScheduleListComponent extends AbstractComponent {
  public messagesScheduled: MessageScheduled[] = [];
  pager: Pager<MessageScheduled> = new Pager<MessageScheduled>({ perPage: 7 });
  selectedMessage: MessageScheduled | null = null;


  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();
  selectedStatus: string = 'todos';
  public statusList: string[] = Object.values(MessageScheduledStatusEnum);
  
  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public attendance: Attendance,
    private messageScheduledService: MessageScheduledService,
    private modalScheduleMessageList: MatDialog,
    private messageScgeduledService: MessageScheduledService,
    public messageService: MessageService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getList();
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList(this.searchParams.status);
  }

  getList(status?: string) {
    this.loadingSpinnerSubject.next(true);
    this.searchParams = {
      company: super.getIDCurrentCompany(),
      contact: this.attendance.contact?._id,
    };

    if (status && status !== 'todos') {
      this.searchParams.status = status;
    }

    this.loadingSpinnerSubject.next(true);
    this.messageScheduledService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (pager: Pager<MessageScheduled>) => {
          this.pager = pager;
          this.messagesScheduled = pager.list;
          this.loadingSpinnerSubject.next(false);
        },
        error: (err) => {
          this.loadingSpinnerSubject.next(false);
        }
      });
  }

  formatTime(time: string): string {
    if (time && time.length === 4) {
      const hours = time.slice(0, 2);
      const minutes = time.slice(2, 4);
      return `${hours}:${minutes}`;
    }
    return time;
  }

  selectMessageForEdit(message: MessageScheduled): void {
    console.log('Mensagem antes de abrir o modal:', message);

    const dialogRef = this.modalScheduleMessageList.open(ChatMessageScheduleComponent, {
      width: '700px',
      data: { message: message, attendance: message.attendance },
    });
    dialogRef.afterClosed().pipe(
    ).subscribe(() => {
      this.getList();
    });
  }

  deleteMessage(message: MessageScheduled): void {
    const dialogRef = this.modalScheduleMessageList.open(ConfirmationComponent, {
      width: '600px',
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.messageScgeduledService.delete(message._id).subscribe({
          next: (response) => {
            this.alertService.success('Mensagem agendada excluída com sucesso');
          },
          error: (error) => {
            this.alertService.error('Erro ao excluir a mensagem agendada');
            console.error(error);
          },
          complete: () => {
            this.getList()
          },
        });
      }
    });
  }
  
  triggerFlashAction(message: MessageScheduled): void {
    message.status = MessageScheduledStatusEnum.MANUAL;
    message.scheduledDate = new Date();
  
    this.messageScheduledService.update(message._id, message).subscribe({
      next: () => {
        if (message.contentType?.startsWith('text')) {
          this.replyText(this.attendance, message.content);
        } else {
          this.replyMedia(this.attendance, message.type, message.content, message.contentType, message.caption);
        }
  
        this.alertService.success('Mensagem enviada imediatamente com sucesso!');
        this.getList(); 
      },
      error: (err) => {
        this.alertService.error('Erro ao enviar a mensagem imediatamente');
        console.error(err);
      },
    });
  }
  
  replyText(attendance: Attendance, text: string): void {
    this.createPostMessage(
      MessageHelper.createMessageFromAttendance(
        attendance,
        MessageTypeEnum.text,
        text,
        null,
        super.getIDCurrentUser()
      )
    );
  }
  
  private createPostMessage(message: Message): void {
    if (message.type === MessageTypeEnum.text && super.getSignatureConversation()) {
      message.content = `> _*${this.getNameCurrentUser()}*_ \n\n ${message.content}`;
    }
  
    this.messageService.create(message).subscribe({
      next: () => {
        console.log('Mensagem enviada com sucesso: ' + message.content);
      },
      error: (err) => {
        this.alertService.error('Erro ao enviar a mensagem.');
        console.error(err);
      }
    });
  }
  
  replyMedia(attendance: Attendance, type: MessageTypeEnum, content: string, contentType: string, caption?: string): void {
    if (this.attendance._id !== attendance._id) {
      this.alertService.warning('O áudio foi cancelado devido a troca de atendimentos.');
      sessionStorage.removeItem(`preview_audio_${attendance._id}`);
      return;
    }
  
    const message = caption && (type === 'image' || type === 'document' || type === 'video')
      ? MessageHelper.createMessageFromAttendance(attendance, type, content, contentType, super.getIDCurrentUser(), caption)
      : MessageHelper.createMessageFromAttendance(attendance, type, content, contentType, super.getIDCurrentUser());
  
    this.createPostMessage(message);
  }
  

  onStatusChange(event: any): void {
    this.getList(event.value);
  }

  translateStatus(status: string): string {
    const statusTranslations = {
      'WAITING': 'Aguardando',
      'DONE': 'Concluído',
      'ERROR': 'Erro',
      'MANUAL': 'Manual'
    };
    return statusTranslations[status] || status; 
  }

  formatWhatsappMessage(text: string): string {
    return UtilHelper.formatWhatsappMessage(text);
  }
}
