import { ChangeDetectorRef, Component, ElementRef, Inject, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Attendance, Channel, Company, Contact, FirebaseService, MessageScheduledService, MessageScheduledStatusEnum, MessageTypeEnum, Traduct, UploadTypeEnum, User } from 'lib-trend-core';
import { MessageScheduled } from 'lib-trend-core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'message-schedule',
  templateUrl: './chat-message-schedule.html',
  styleUrls: ['./chat-message-schedule.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatMessageScheduleComponent extends AbstractComponent {
  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();
  file!: File;
  form: FormGroup;
  private message: MessageScheduled;
  contentType: string = ''
  attachment: any;

  @ViewChild('messageTextarea') messageTextarea!: ElementRef<HTMLTextAreaElement>;

  showContainerEmoji: boolean = false;
  translator!: Traduct;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: { attendance?: Attendance; message?: MessageScheduled },
    private fb: FormBuilder,
    private messageScgeduledService: MessageScheduledService,
    private firebaseService: FirebaseService,
    public _matDialogRef: MatDialogRef<MessageScheduled>,
  ) {
    super(injector);
    this.message = data.message
    this.form = this.fb.group({
      scheduledDay: ['', Validators.required],
      scheduledHour: ['', Validators.required],
      message: ['',],
      attachment: [null], 
    });
  }

  ngOnInit(): void {
    
    this.isNew = !this.data?.message;
  
    if (!this.isNew) {
      const { scheduledDate, scheduledTime, caption, content, contentType } = this.data.message;
  
      this.form.patchValue({
        scheduledDay: scheduledDate,
        scheduledHour: scheduledTime,
        message: caption || content,
      });
  
      this.attachment = content; 
      this.contentType = contentType;
  
      if (caption === "") {
        this.form.patchValue({ message: "" });
      }
      console.log('Attachment on init:', this.attachment);
      console.log('Content type on init:', this.contentType);
    }
  }
  
  saveMessageScheduled(): void {
    if (this.form.invalid) {
      this.alertService.warning('Por favor, preencha todos os campos obrigatórios.');
      return;
    }
    const scheduledDay = this.form.get('scheduledDay').value;
    let scheduledHour = this.form.get('scheduledHour').value?.replace(/^(\d{2})(\d{2})$/, '$1:$2');
    
    if (!scheduledDay || !/^\d{2}:\d{2}$/.test(scheduledHour)) {
      this.alertService.error('Data e hora agendadas são obrigatórias e devem estar no formato HH:mm.');
      return;
    }
    
    const [hour, minute] = scheduledHour.split(':').map(Number);
    const scheduledDateTime = new Date(scheduledDay);
    scheduledDateTime.setHours(hour, minute, 0, 0);
    
    const currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + 2);
    
    if (scheduledDateTime < currentTime) {
      this.alertService.error('A data e hora agendadas devem ser pelo menos 2 minutos acima da hora atual.');
      return;
    }
  
    const message = this.form.get('message')?.value;
  
    let contentType = this.file ? this.file.type : this.contentType || MessageTypeEnum.text;
    let messageType;
  
    if (contentType.includes('application/pdf')) {
      messageType = MessageTypeEnum.document;
    } else if (contentType.includes('image/')) {
      messageType = MessageTypeEnum.image;
    } else if (contentType.includes('audio/')) {
      messageType = MessageTypeEnum.audio;
    } else if (contentType.includes('video/')) {
      messageType = MessageTypeEnum.video;
    } else {
      messageType = MessageTypeEnum.text;
    }
  
    if (messageType === MessageTypeEnum.text && !message?.trim()) {
      this.alertService.warning('É necessário preencher a mensagem antes de salvar.');
      return;
    }

    const messageScheduled = <MessageScheduled>{
      to: this.data.attendance.contact.phone,
      content: this.attachment || message,
      caption: this.attachment ? message : null,
      contact: this.isNew
        ? { _id: this.data.attendance.contact._id }
        : { _id: this.message.contact._id } as Contact,
      channel: this.isNew
        ? { _id: this.data.attendance.channel._id }
        : { _id: this.message.channel._id } as Channel,
      company: { _id: super.getIDCurrentCompany() } as Company,
      attendance: { _id: this.data.attendance._id } as Attendance,
      user: { _id: super.getIDCurrentUser() } as User,
      type: messageType,
      contentType,
      removed: false,
      scheduledDate: scheduledDay,
      scheduledTime: scheduledHour,
      status: MessageScheduledStatusEnum.WAITING,
    };
  
    const saveObservable = this.isNew
      ? this.messageScgeduledService.create(messageScheduled)
      : this.messageScgeduledService.update(this.message._id, messageScheduled);
  
    saveObservable.subscribe({
      next: () => {
        const successMessage = this.isNew
          ? 'Mensagem agendada criada com sucesso'
          : 'Mensagem agendada atualizada com sucesso';
        this.alertService.success(successMessage);
        this._matDialogRef.close();
      },
      error: () => {
        const errorMessage = this.isNew
          ? 'Erro ao agendar a mensagem'
          : 'Erro ao atualizar a mensagem agendada';
        this.alertService.error(errorMessage);
      },
    });
  }
  
  private isValidMediaUrl(url: string): boolean {
    const mediaExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'mp4', 'mp3', 'wav', 'avi'];
    const extension = url?.split('.').pop()?.toLowerCase();
    return mediaExtensions.includes(extension);
  }
  
  
  onMidiaChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) return;
  
    const file = input.files[0];
    if (file) {
      this.file = file;
      const mimeType = file.type;
  
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          this.loadingSpinnerSubject.next(true);
          const filename = `${file.name}_${new Date().getTime()}`;
  
          const snapshot = await this.firebaseService.uploadFile(file, filename, UploadTypeEnum.MESSAGE);
          this.loadingSpinnerSubject.next(false);
  
          this.attachment = snapshot.url;
          this.contentType = mimeType;
  
          this.form.patchValue({
            attachment: snapshot.url,
          });
        } catch (error) {
          this.loadingSpinnerSubject.next(false);
          console.error('Erro no upload do arquivo:', error);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }
  
  applyFormat(format: string) {
    const textarea = this.messageTextarea.nativeElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = textarea.value.substring(start, end);

    let formattedText = '';

    switch (format) {
      case 'bold':
        formattedText = `*${selectedText}*`;
        break;
      case 'italic':
        formattedText = `_${selectedText}_`;
        break;
      case 'underline':
        formattedText = `~${selectedText}~`;
        break;
      default:
        console.log('Formato desconhecido');
        return;
    }

      textarea.value =
      textarea.value.substring(0, start) +
      formattedText +
      textarea.value.substring(end);

    textarea.setSelectionRange(start + formattedText.length, start + formattedText.length);
    textarea.focus();
  }

  isMessageValid(): boolean {
    const messageContent = this.form.get('message')?.value?.trim();
    const attachment = this.form.get('attachment')?.value?.trim();
  
    if (!this.isNew && !messageContent || !attachment) {
      return false;
    }

    return !(messageContent || attachment || this.message?.content);
  }
  
  removeAttachment(): void {
    if (this.attachment) {
      this.attachment = null;
      this.file = null;
      this.contentType = 'text'; 
      this.form.patchValue({ attachment: null });
  
      this.alertService.success('Arquivo removido com sucesso.');
    } else {
      this.alertService.warning('Nenhum arquivo para remover.');
    }
  }

}
