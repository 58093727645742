
import { AfterViewInit, Component, ElementRef, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Fancybox } from '@fancyapps/ui';
import { Store } from '@ngrx/store';
import { ButtonsComponentObject, HeaderComponentObject, PhoneNumberButtonsComponentObject, TemplateMessageObject, URLButtonsComponentObject } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Attendance, ConfirmationComponent, Message, MessageService, MessageTypeEnum, UtilHelper, WaTemplateResponse } from 'lib-trend-core';
import { environment } from '../../../../../../../environments/environment';
import { getMessages } from '../../../state/actions';
import { AppState } from '../../../state/app.state';

@Component({
  selector: 'chat-messages-component',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss']
})
export class ChatMessageComponent extends AbstractComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  selectedTemplate: Partial<WaTemplateResponse>;

  @ViewChild('messageContainer') messageContainer: ElementRef<HTMLDivElement>;

  @Input() attendance: Attendance;
  @Input() messages: Array<Message>;

  constructor(
    injector: Injector,
    private messageService: MessageService,
    private dialog: MatDialog,
    private store: Store<AppState>,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['attendance'] && changes['attendance'].currentValue) {
      this.attendance = changes['attendance'].currentValue;
    }
    if (changes['messages'] && changes['messages'].currentValue) {
      this.messages = changes['messages'].currentValue;
      this.scrollToBottom();
    }
  }

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  override ngOnDestroy() {
    if (this.messageContainer && this.messageContainer.nativeElement) {
      Fancybox.unbind(this.messageContainer.nativeElement);
    }
    Fancybox.close();
  }

  initComponent() {
    Fancybox.bind('[data-fancybox]', {
      Thumbs: true,
      hideScrollbar: false,
      Carousel: {
        transition: 'slide',
      },
      Images: {
        zoom: true,
      },
    });
  }

  getTemplateButtons(message: Message): Array<PhoneNumberButtonsComponentObject | URLButtonsComponentObject> {
    const template = message.metadata.template as TemplateMessageObject;
    if (template && template.components) {
      const buttonsComponent = template.components.find(component => component.type === 'BUTTONS') as ButtonsComponentObject;
      return buttonsComponent ? buttonsComponent.buttons : [];
    }

    return [];
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        if (this.messageContainer) {
          this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
        }
      }, 100);
    } catch (err) {
      console.error(err);
    }
  }

  formatWhatsappMessage(text: string): string {
    return UtilHelper.formatWhatsappMessage(text);
  }

  isTemplateMidia(message: Message) {
    const template = <TemplateMessageObject>message.metadata.template;
    const headerComponent = <HeaderComponentObject>template?.components.find(c => c.type === 'HEADER');
    return (!!headerComponent && headerComponent!.format !== 'TEXT') ?? false;
  }

  isTemplateImage(message: Message): boolean {
    const template = <TemplateMessageObject>message.metadata.template;
    const headerComponent = <HeaderComponentObject>template?.components.find(c => c.type === 'HEADER');
    return headerComponent?.format === 'IMAGE';
  }

  isTemplateDocument(message: Message): boolean {
    const template = <TemplateMessageObject>message.metadata.template;
    const headerComponent = <HeaderComponentObject>template?.components.find(c => c.type === 'HEADER');
    return headerComponent?.format === 'DOCUMENT';
  }

  isTemplateVideo(message: Message): boolean {
    const template = <TemplateMessageObject>message.metadata.template;
    const headerComponent = <HeaderComponentObject>template?.components.find(c => c.type === 'HEADER');
    return headerComponent?.format === 'VIDEO';
  }

  getContentTemplateMessage(message: Message): string {
    const template = message.metadata.template as TemplateMessageObject;
    const headerComponent = template?.components.find(c => c.type === 'BODY') as HeaderComponentObject;
    if (!headerComponent || !headerComponent.text) {
      return '';
    }
    let text = headerComponent.text;
    const params = message.metadata.params;

    if (params && typeof params === 'object') {

      Object.keys(params).forEach(key => {
        const placeholder = `{{1{{${key}}}}`;
        text = text.replace(new RegExp(placeholder), params[key]);
      });
    }

    return text;
  }

  getTypeAndCaption(message: Message): { isCaption: boolean, type: string } {
    const mimeType = this.helperExtension(message.content);
    return { isCaption: message.caption ? true : false, type: mimeType }
  }

  private helperExtension(midia: string): 'image' | 'location' | 'video' | 'document' {
    const decodedUrl = decodeURIComponent(midia);

    const fileNameWithQuery = decodedUrl.split('/').pop() || '';
    const fileName = fileNameWithQuery.split('?')[0];

    const extension = fileName.split('.').pop()?.toLowerCase();

    const regex = /_(\d+)/;
    const nameWithoutPart = extension.replace(regex, '');

    const mimeType = UtilHelper.getMimeType(nameWithoutPart);

    switch (mimeType) {
      case 'video':
        return 'video';
      case 'image':
        return 'image';
      default:
        return 'document'
    }
  }

  scrollToMessage(event: MouseEvent, messageId: string) {
    event.preventDefault();
    const element = document.getElementById(messageId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  isMedia(type: string): boolean {
    return type === MessageTypeEnum.audio || type === MessageTypeEnum.image || type === MessageTypeEnum.video;
  }

  deleteMessageForEveryone(message: Message): void {
    const idUser: unknown = message?.user;

    if (this.isAttendant() && idUser !== super.getCurrentUserUser()._id) {
      this.alertService.warning('Você não pode apagar uma mensagem que não é sua.');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: { question: 'Tem certeza que deseja apagar essa mensagem?' },
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.messageService.delete(message._id).subscribe({
          next: () => {
            this.alertService.success('Mensagem apagada com sucesso!')
            this.store.dispatch(getMessages({ idAttendance: this.attendance._id }));
          },
          error: (err) => { },
          complete: () => {
          },
        });
      };
    });
  }

  public get env() {
    return environment.env;
  }

  getImageDefault(event: Event): void {
    UtilHelper.onStickerError(event);
  }
}