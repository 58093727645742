<div class="w-full">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">Adicionar mensagem agendada</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e agende uma mensagem</h6>

  <section class="w-full flex flex-row flex-wrap mt-4">

    <input id="tab-one" type="radio" name="tabs" class="peer/tab-one opacity-0 absolute" checked />
    <label for="tab-one"
      class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-one:border-b-2 peer-checked/tab-one:border-[#4213F6] peer-checked/tab-one:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
      Mensagem
    </label>
    <div class="hidden peer-checked/tab-one:flex w-full items-center">
      <div class="flex flex-1 flex-col mt-4">

        <form [formGroup]="form" (ngSubmit)="saveMessageScheduled()">
          <div class="textarea-wrapper">
            <div class="flex gap-2 mb-[15px]">
              <div class="flex flex-col w-[60%]">
                <label class="font-semibold text-black mt-[15px]">Data *</label>
                <div id="input-phone" 
                     class="input-field h-[46px] flex justify-between items-center w-full">
                  <input  readonly [matDatepicker]="picker" formControlName="scheduledDay" placeholder="00/00/0000"
                    class="outline-none border-none focus:ring-0 focus:border-transparent bg-transparent w-full" (focus)="picker.open()" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <field-error-component 
                  [field]="'scheduledDay'" 
                  [label]="'Data'" 
                  [formGroup]="form">
                </field-error-component>
              </div>
        
              <div class="flex flex-col w-[60%]">
                <label class="font-semibold text-black mt-[15px]">Hora *</label>
                <input 
                  class="input-field" 
                  formControlName="scheduledHour" 
                  placeholder="ex: 08:00" 
                  mask="00:00">
                <field-error-component 
                  [field]="'scheduledHour'" 
                  [label]="'Hora'" 
                  [formGroup]="form">
                </field-error-component>
              </div>
            </div>


<div class="flex w-full gap-2">

  <div class="textarea-container w-2/3 ">
    <textarea 
    #messageTextarea 
    formControlName="message" 
    class="input-field h-[150px]" 
    placeholder="Digite sua mensagem aqui. Exemplo: 'Olá, bom dia! Esta é uma mensagem programada.'"
    [attr.disabled]="contentType === 'audio/mpeg' ? true : null">
  </textarea>

    <div class="icon-container flex items-center">
      <mat-icon (click)="applyFormat('bold')" class="cursor-pointer ml-2 text-black">format_bold</mat-icon>
      <mat-icon (click)="applyFormat('italic')" class="cursor-pointer ml-2 text-black">format_italic</mat-icon>
      <mat-icon (click)="applyFormat('underline')"
        class="cursor-pointer ml-2 text-black">format_underline</mat-icon>
      <!-- <mat-icon (click)="toogleContainerEmoji()"
        class="cursor-pointer ml-2 text-black">sentiment_satisfied</mat-icon>
      <div class="relative" id="emoji-container">
        <ng-container *ngIf="showContainerEmoji" class="fixed bottom-100 z-[999999] right-[40px] lg:bottom-100">
          <emoji-mart (emojiSelect)="addEmoji($event)" [i18n]="translator" [darkMode]="false">
          </emoji-mart>
        </ng-container>
      </div> -->
    </div>
  </div>

  <div class="relative flex flex-col items-center justify-center w-1/3 h-[150px] border-2 border-gray-300 border-dashed rounded-lg">
    <loading-component class="loading-template" *ngIf="loadingSpinner$ | async"></loading-component>
  
    <ng-container *ngIf="!(loadingSpinner$ | async)">
      <ng-container *ngIf="(!attachment || !attachment.trim()) || contentType === 'text'">
        <div>
          <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#E6E6FF" />
            <rect x="4.5" y="4" width="48" height="48" rx="24" stroke="#F1F1FF" stroke-width="8" />
            <g clip-path="url(#clip0_6074_436)">
              <path
                d="M32.5 32L28.5 28M28.5 28L24.5 32M28.5 28V37M36.89 34.39C37.8653 33.8583 38.6358 33.0169 39.0798 31.9986C39.5239 30.9804 39.6162 29.8432 39.3422 28.7667C39.0682 27.6901 38.4434 26.7355 37.5666 26.0534C36.6898 25.3714 35.6108 25.0007 34.5 25H33.24C32.9373 23.8292 32.3731 22.7423 31.5899 21.821C30.8067 20.8996 29.8248 20.1678 28.7181 19.6806C27.6113 19.1933 26.4085 18.9633 25.2001 19.0079C23.9916 19.0524 22.809 19.3703 21.7411 19.9376C20.6732 20.505 19.7479 21.3071 19.0346 22.2836C18.3213 23.26 17.8387 24.3855 17.6229 25.5754C17.4072 26.7652 17.4641 27.9885 17.7892 29.1532C18.1143 30.318 18.6992 31.3938 19.5 32.3"
                stroke="#4213F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_6074_436">
                <rect width="24" height="24" fill="white" transform="translate(16.5 16)" />
              </clipPath>
            </defs>
          </svg>
        </div>
  
        <div class="cursor-pointer">
          <p class="text-sm text-gray-500 dark:text-gray-400 items-center flex flex-col justify-center">
            <span class="font-semibold text-[#4213F6] text-sm items-center">Clique aqui para carregar</span>
            <span class="flex items-center justify-center">ou arraste e solte</span>
            <span class="flex items-center justify-center text-center text-gray-500">PDF, PNG, JPG ou JPEG</span>
          </p>
        </div>
      </ng-container>
  
      <img 
        class="rounded-lg cursor-pointer w-full h-full object-cover" 
        *ngIf="attachment && contentType?.includes('image/')" 
        [src]="attachment" 
        alt="Uploaded image"
      />
      <video 
        class="rounded-lg cursor-pointer w-full h-full object-cover" 
        *ngIf="attachment && contentType?.includes('video/')" 
        [src]="attachment" 
        controls>
        Seu navegador não suporta a reprodução de vídeos.
      </video>
  
      <div *ngIf="attachment && contentType?.includes('application/pdf')" class="flex items-center justify-center ">
        <mat-icon class="text-black">description</mat-icon>
        <p>Documento carregado.</p>
      </div>
  
      <div *ngIf="attachment && contentType?.includes('audio/')" class="flex items-center justify-center p-4">
        <mat-icon class="text-black">audiotrack</mat-icon>
        <p>Áudio carregado.</p>
      </div>
  
      <input
      id="dropzone-file"
      type="file"
      (change)="onMidiaChange($event)"
      class="absolute inset-0 opacity-0 cursor-pointer"
     />
      <div class="bottom-0 absolute cursor-pointer">
        <button 
          *ngIf="attachment && attachment.trim() && (contentType?.includes('image/') || contentType?.includes('video/') || contentType?.includes('application/pdf') || contentType?.includes('audio/'))" 
          (click)="removeAttachment()" 
          class="bg-red-600 text-white flex items-center rounded">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>

            <field-error-component 
              [field]="'message'" 
              [label]="'Mensagem'" 
              [formGroup]="form">
            </field-error-component>
          </div>
        
          <div class="flex justify-between items-center w-full gap-2 mt-[15px]">
            <button [mat-dialog-close]=""
              class="h-[45px] flex flex-1 justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100" 
              type="button">Cancelar</button>
              <button
              class="h-[45px] flex flex-1 justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal hover:bg-[#1340f6]"
              type="submit"
              [disabled]="(loadingSpinner$ | async) || isMessageValid()"
            >
              Adicionar
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>
