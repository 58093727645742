<div class="chat-container" #messageContainer>
  <ng-container *ngIf="messages.length < 1">
    <div class="flex flex-col w-full justify-center items-center text-center pt-10">
      <mat-spinner></mat-spinner>
      @if (attendance.channel.type === 'EVOLUTION_API') {
      <h5 class="text-[#bdbdbd] m-4 w-[30%]">Esse atendimento não
        contém nenhuma mensagem até o momento. Digite uma mensagem</h5>
      } @else {
      <p class="text-[#bdbdbd] font-bold m-4">Carregando mensagens...</p>
      }
    </div>
  </ng-container>

  @for (message of messages; track $index) {
  @if (message.type === 'image' || message.type === 'video' || message.type === 'audio' || message.type === 'document'
  || message.type === 'sticker' || message.type === 'template' || message.type === 'text' || message.type === 'button')
  {

  <div class="chat-message user-message" style="justify-content: flex-start;"
    *ngIf="!message.automated && !message.user" #messageRef [attr.data-message-id]="message._id">
    <div class="message-content">
      <div class="message-header">
        <h3 class="username">{{message.user?.name}}</h3>
      </div>
      <div class="message-body" [id]="message._id">

        <chat-message-reply-component [attendance]="attendance" [message]="message"></chat-message-reply-component>

        <div class="content-message-img w-[300px]" *ngIf="message.type === 'image'">
          <a data-fancybox="gallery" [href]="message.content">
            <img class="img-max-size" [src]="message.content" />
          </a>
          <div class="w-full text-left overflow-wrap break-words">
            <p *ngIf="!!message.caption" [innerHTML]="formatWhatsappMessage(message.caption)">
            </p>
          </div>
          <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-1"><span
              class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span></small>
        </div>

        <div *ngIf="message.type === 'text'">
          <p class="message-text-user max-w-[700px] break-words mt-2"
            [innerHTML]="formatWhatsappMessage(message.content)">
          </p>

          <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-1">
            <span class="timestamp">{{ message.updatedAt | date: 'dd/MM/yyyy HH:mm' }}</span>
          </small>
        </div>

        <div *ngIf="message.type === 'template'">
          <p class="message-text-user" [innerHTML]="formatWhatsappMessage(message.content)"></p>
        </div>

        <div *ngIf="message.type === 'button'">
          <p class="message-text-user mt-2" [innerHTML]="formatWhatsappMessage(message.content)"></p>
          <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-2"><span
              class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span></small>
        </div>

        <div *ngIf="message.type === 'video'">
          <a data-fancybox="gallery" [href]="message.content">
            <video width="320" height="240" controls>
              <source [src]="message.content" type="video/mp4">
              Seu navegador nao suporta tag de vídeo.
            </video>
          </a>
          <p *ngIf="!!message.caption" class="flex message-text-user max-w-[600px] break-words items-center"
            [ngClass]="{'message-text-automated': message.automated}"
            [innerHTML]="formatWhatsappMessage(message.caption)"></p>
          <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-2"><span
              class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span></small>
        </div>

        <div *ngIf="message.type === 'audio'">
          <div class="relative max-w-[600px] rounded-lg overflow-hidden shadow-lg bg-[#F1F3F4]">
            <audio class="rounded-lg" [src]="message.content" controls>
              <p>Seu navegador não tem suporte para </p>
            </audio>
            <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-1"><span
                class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span></small>
          </div>

        </div>

        <div class=" max-w-[300px]" *ngIf="message.type === 'sticker'">
            <img class="w-[300px]" [src]="message.content" alt="sticker" (error)="getImageDefault($event)">
        </div>

        <div class="w-full items-center" *ngIf="message.type === 'document'">
          <a class=" space-x-2 text-black" [href]="message.content" target="_blank">

            <div class="w-[300px]items-center flex">
              <div class="w-[50px]">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="31.119px" height="31.119px"
                  viewBox="0 0 31.119 31.119" fill="black">
                  <g>
                    <path
                      d="M26.065,14.984c-0.1-0.666-0.671-1.146-1.343-1.146H13.836l-1.977-2.34c-0.419-0.492-1.034-0.802-1.681-0.802H3.105c-0.504,0-0.983,0.236-1.309,0.622c-0.325,0.387-0.464,0.917-0.379,1.414l0.188,1.106H1.481c-0.396,0-0.771,0.161-1.027,0.461c-0.26,0.301-0.373,0.693-0.312,1.084l2.196,14.248c0.133,0.857,0.871,1.488,1.738,1.488h22.797c0.396,0,0.771-0.174,1.027-0.471c0.257-0.3,0.373-0.689,0.314-1.08L26.065,14.984z" />
                    <path
                      d="M20.645,4.351H12.13c-0.587,0-1.063,0.53-1.063,1.118s0.477,1.118,1.063,1.118h8.515c0.589,0,1.064-0.53,1.064-1.118S21.232,4.351,20.645,4.351z" />
                    <path
                      d="M30.677,12.186c-0.245-0.285-0.604-0.464-0.981-0.464h-2.416l0.55,5.095l1.313,8.901l1.838-12.492C31.032,12.854,30.924,12.472,30.677,12.186z" />
                    <path
                      d="M9.024,2.055h14.864v10.091h2.056V1.307C25.943,0.591,25.345,0,24.629,0H8.191C7.405,0,6.789,0.647,6.789,1.433v7.812h2.235V2.055z" />
                    <path
                      d="M20.645,8.46H12.13c-0.511,0-0.937,0.404-1.04,0.899c0.729,0.186,1.392,0.583,1.891,1.168l0.155,0.168h7.509c0.589,0,1.064-0.53,1.064-1.118C21.709,8.989,21.232,8.46,20.645,8.46z" />
                  </g>
                </svg>
              </div>
              <h1 class=" mt-2 ml-[10px] w-[300px] items-center">Clique aqui para abrir seu documento.</h1>
            </div>
          </a>
          <p *ngIf="!!message.caption" class="message-text-user mt-[15px] max-w-[600px] break-words items-center"
            [innerHTML]="formatWhatsappMessage(message.caption)"></p>
          <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-1"><span
              class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span></small>
        </div>

      </div>
      <chat-message-reactions-component [message]="message"></chat-message-reactions-component>
    </div>
  </div>

  <!-- @TODO: Message of attendant -->
  <div class="chat-message operator-message " [id]="message._id" style="justify-content: flex-end;"
    *ngIf="message.automated  || message.user" #messageRef [attr.data-message-id]="message._id">
    <div class="message-content">
      <div class="message-header">
        <h3 class="username mr-1">{{message?.user?.name}}</h3>
      </div>
      <div class="message-body"
        [ngClass]="{'message-automated': message.automated, 'message-deleted': message.removed}">
        <chat-message-reply-component [attendance]="attendance" [message]="message"></chat-message-reply-component>

        <!-- @TODO: Message of type image -->
        <div class="content-message-img max-w-[300px]" *ngIf="message.type === 'image'">
          @if (!message.removed) {
          @if ((env !== 'prod') && ((attendance?.channel.type === 'EVOLUTION_API' || attendance?.channel.type ===
          'EVOLUTION_GO_API') && !message.automated) &&
          ((isAttendant() && getCurrentUserUser().deleteMessage) || isAdmin() || isSupervisor())) {
          <button (click)="deleteMessageForEveryone(message)" class="h-[20px] w-full flex items-center justify-end">
            <mat-icon class="text-[#4213F6] text-[1rem]">delete</mat-icon>
          </button>
          }
          <a data-fancybox="gallery" [href]="message.content">
            <img class="img-max-size" [src]="message.content" />
          </a>
          <p *ngIf="!!message.caption" class="message-text-operator max-w-[600px] break-words items-center"
            [ngClass]="{'message-text-automated': message.automated}"
            [innerHTML]="formatWhatsappMessage(message.caption)"></p>

          <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-1"><span
              class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span></small>
          } @else {
          <div class="flex flex-col items-end">
            <p class="line-through break-words" [ngClass]="{'message-text-automated': message.automated}"
              [innerHTML]="formatWhatsappMessage('Imagem removida')">
            </p>
            <small class="small-message flex items-end justify-end text-[10px] pt-1"
              [ngClass]="{'small-message-deleted': message.removed}">
              <span class="timestamp">{{ message.updatedAt | date : 'dd/MM/yyyy HH:mm' }}</span>
            </small>
          </div>
          }
        </div>

        <!-- @TODO: Message of type text -->
        <div *ngIf="message.type === 'text'" class="w-full">
          @if (!message.removed) {
          @if ((env !== 'prod') && ((attendance?.channel.type === 'EVOLUTION_API' || attendance?.channel.type ===
          'EVOLUTION_GO_API') && !message.automated) &&
          ((isAttendant() &&
          getCurrentUserUser().deleteMessage) || isAdmin() || isSupervisor())) {
          <button (click)="deleteMessageForEveryone(message)" class="h-[20px] w-full flex items-center justify-end">
            <mat-icon class="text-[#4213F6] text-[1rem]">delete</mat-icon>
          </button>
          }
          <div class="flex items-center justify-between w-full">
            <p class="message-text-operator max-w-[700px] break-words"
              [ngClass]="{'message-text-automated': message.automated}"
              [innerHTML]="formatWhatsappMessage(message.content)">
            </p>
          </div>
          <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-1"><span
              class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span></small>
          } @else {
          <p class="line-through max-w-[700px] break-words" [ngClass]="{'message-text-automated': message.automated}"
            [innerHTML]="formatWhatsappMessage(message.content)">
          </p>
          <small class="small-message flex items-end justify-end text-[10px] pt-1"
            [ngClass]="{'small-message-deleted': message.removed}"><span class="timestamp">{{message.updatedAt | date :
              'dd/MM/yyyy HH:mm'}}</span></small>
          }
        </div>

        <!-- @TODO: Message of type template -->
        <div class="flex justify-center flex-col" *ngIf="message.type === 'template'">
          @if (isTemplateMidia(message)) {
          @if (isTemplateImage(message)) {
          <div class="flex justify-center  flex-col items-center max-w-[300px]">
            <a data-fancybox="gallery" [href]="message.content">
              <img [src]="message.content" alt="Image" class="max-w-[600px] w-full object-contain " />
            </a>
            <p class="message-text-operator mt-2 break-words items-center w-full "
              [ngClass]="{'message-text-automated': message.automated}"
              [innerHTML]="getContentTemplateMessage(message)">
            </p>

          </div>
          }
          @if (isTemplateDocument(message)) {
          <div class="flex justify-center  flex-col items-center max-w-[350px]">
            <a class="space-x-2 text-black my-2" [href]="message.content" target="_blank">
              <div class="w-[300px]items-center flex">
                <div class="w-[50px]">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="31.119px" height="31.119px"
                    viewBox="0 0 31.119 31.119" fill="black">
                    <g>
                      <path
                        d="M26.065,14.984c-0.1-0.666-0.671-1.146-1.343-1.146H13.836l-1.977-2.34c-0.419-0.492-1.034-0.802-1.681-0.802H3.105c-0.504,0-0.983,0.236-1.309,0.622c-0.325,0.387-0.464,0.917-0.379,1.414l0.188,1.106H1.481c-0.396,0-0.771,0.161-1.027,0.461c-0.26,0.301-0.373,0.693-0.312,1.084l2.196,14.248c0.133,0.857,0.871,1.488,1.738,1.488h22.797c0.396,0,0.771-0.174,1.027-0.471c0.257-0.3,0.373-0.689,0.314-1.08L26.065,14.984z" />
                      <path
                        d="M20.645,4.351H12.13c-0.587,0-1.063,0.53-1.063,1.118s0.477,1.118,1.063,1.118h8.515c0.589,0,1.064-0.53,1.064-1.118S21.232,4.351,20.645,4.351z" />
                      <path
                        d="M30.677,12.186c-0.245-0.285-0.604-0.464-0.981-0.464h-2.416l0.55,5.095l1.313,8.901l1.838-12.492C31.032,12.854,30.924,12.472,30.677,12.186z" />
                      <path
                        d="M9.024,2.055h14.864v10.091h2.056V1.307C25.943,0.591,25.345,0,24.629,0H8.191C7.405,0,6.789,0.647,6.789,1.433v7.812h2.235V2.055z" />
                      <path
                        d="M20.645,8.46H12.13c-0.511,0-0.937,0.404-1.04,0.899c0.729,0.186,1.392,0.583,1.891,1.168l0.155,0.168h7.509c0.589,0,1.064-0.53,1.064-1.118C21.709,8.989,21.232,8.46,20.645,8.46z" />
                    </g>
                  </svg>
                </div>
                <h1 class=" mt-2 ml-[10px] w-[300px] items-center">Clique aqui para abrir seu documento.</h1>
              </div>
            </a>
            <p class="message-text-operator mt-2 break-words items-center w-full "
              [ngClass]="{'message-text-automated': message.automated}"
              [innerHTML]="getContentTemplateMessage(message)">
            </p>

          </div>
          }
          @if (isTemplateVideo(message)) {
          <div class="flex justify-center  flex-col items-center max-w-[300px]">
            <div class="flex items-center justify-between w-full">
              <a data-fancybox="gallery" [href]="message.content">
                <video width="320" height="240" controls>
                  <source [src]="message.content" type="video/mp4">
                  Seu navegador não suporta a tag de vídeo.
                </video>
              </a>
            </div>
            <p class="message-text-operator mt-2 break-words items-center w-full "
              [ngClass]="{'message-text-automated': message.automated}"
              [innerHTML]="getContentTemplateMessage(message)">
            </p>
          </div>
          }
          }@else {
          <div>
            <p class="message-text-operator max-w-[600px] break-words items-center"
              [ngClass]="{'message-text-automated': message.automated}"
              [innerHTML]="formatWhatsappMessage(message.content)">

            </p>
            <div *ngIf="getTemplateButtons(message).length > 0">
              <div class="mt-2 text-sm flex flex-col items-center w-full">
                <ng-container *ngFor="let button of getTemplateButtons(message); let i = index">
                  <div class="flex items-center justify-center w-full border-t border-gray-500 py-1">
                    <mat-icon style="font-size: 16px; color: #000;">reply</mat-icon>
                    <span class="ml-2">{{ button.text }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          }


          <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-1"><span
              class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span></small>
        </div>

        <!-- @TODO: Message of type video -->
        <div *ngIf="message.type === 'video'" class="w-full">
          @if (!message.removed) {
          @if ((env !== 'prod') && ((attendance?.channel.type === 'EVOLUTION_API' || attendance?.channel.type ===
          'EVOLUTION_GO_API') && !message.automated) &&
          ((isAttendant() && getCurrentUserUser().deleteMessage) || isAdmin() || isSupervisor())) {
          <button (click)="deleteMessageForEveryone(message)" class="h-[20px] w-full flex items-center justify-end">
            <mat-icon class="text-[#4213F6] text-[1rem]">delete</mat-icon>
          </button>
          }
          <div class="flex items-center justify-between w-full">
            <a data-fancybox="gallery" [href]="message.content">
              <video width="320" height="240" controls>
                <source [src]="message.content" type="video/mp4">
                Seu navegador não suporta a tag de vídeo.
              </video>
            </a>
          </div>

          <p *ngIf="!!message.caption" class="message-text-operator max-w-[600px] break-words items-center"
            [ngClass]="{'message-text-automated': message.automated}"
            [innerHTML]="formatWhatsappMessage(message.caption)">
          </p>
          <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-2">
            <span class="timestamp">{{ message.updatedAt | date : 'dd/MM/yyyy HH:mm' }}</span>
          </small>
          } @else {
          <div class="flex flex-col items-end">
            <p class="line-through max-w-[600px] break-words" [ngClass]="{'message-text-automated': message.automated}"
              [innerHTML]="formatWhatsappMessage('Vídeo removido')">
            </p>
            <small class="small-message flex items-end justify-end text-[10px] pt-1"
              [ngClass]="{'small-message-deleted': message.removed}">
              <span class="timestamp">{{ message.updatedAt | date : 'dd/MM/yyyy HH:mm' }}</span>
            </small>
          </div>
          }
        </div>


        <!-- @TODO: Message of type document -->
        <div class="w-full items-center" *ngIf="message.type === 'document'">
          @if (!message.removed) {
          @if ((env !== 'prod') && ((attendance?.channel.type === 'EVOLUTION_API' || attendance?.channel.type ===
          'EVOLUTION_GO_API') && !message.automated) &&
          ((isAttendant() && getCurrentUserUser().deleteMessage) || isAdmin() || isSupervisor())) {
          <button (click)="deleteMessageForEveryone(message)" class="h-[20px] w-full flex items-center justify-end">
            <mat-icon class="text-[#4213F6] text-[1rem]">delete</mat-icon>
          </button>
          }
          <a class=" space-x-2 text-black" [href]="message.content" target="_blank">
            <div class="w-[300px]items-center flex">
              <div class="w-[50px]">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="31.119px" height="31.119px"
                  viewBox="0 0 31.119 31.119" fill="black">
                  <g>
                    <path
                      d="M26.065,14.984c-0.1-0.666-0.671-1.146-1.343-1.146H13.836l-1.977-2.34c-0.419-0.492-1.034-0.802-1.681-0.802H3.105c-0.504,0-0.983,0.236-1.309,0.622c-0.325,0.387-0.464,0.917-0.379,1.414l0.188,1.106H1.481c-0.396,0-0.771,0.161-1.027,0.461c-0.26,0.301-0.373,0.693-0.312,1.084l2.196,14.248c0.133,0.857,0.871,1.488,1.738,1.488h22.797c0.396,0,0.771-0.174,1.027-0.471c0.257-0.3,0.373-0.689,0.314-1.08L26.065,14.984z" />
                    <path
                      d="M20.645,4.351H12.13c-0.587,0-1.063,0.53-1.063,1.118s0.477,1.118,1.063,1.118h8.515c0.589,0,1.064-0.53,1.064-1.118S21.232,4.351,20.645,4.351z" />
                    <path
                      d="M30.677,12.186c-0.245-0.285-0.604-0.464-0.981-0.464h-2.416l0.55,5.095l1.313,8.901l1.838-12.492C31.032,12.854,30.924,12.472,30.677,12.186z" />
                    <path
                      d="M9.024,2.055h14.864v10.091h2.056V1.307C25.943,0.591,25.345,0,24.629,0H8.191C7.405,0,6.789,0.647,6.789,1.433v7.812h2.235V2.055z" />
                    <path
                      d="M20.645,8.46H12.13c-0.511,0-0.937,0.404-1.04,0.899c0.729,0.186,1.392,0.583,1.891,1.168l0.155,0.168h7.509c0.589,0,1.064-0.53,1.064-1.118C21.709,8.989,21.232,8.46,20.645,8.46z" />
                  </g>
                </svg>
              </div>
              <h1 class=" mt-2 ml-[10px] w-[300px] items-center">Clique aqui para abrir seu documento.</h1>
            </div>
          </a>
          <p *ngIf="!!message.caption" class="message-text-operator mt-[15px] max-w-[600px] break-words items-center"
            [innerHTML]="formatWhatsappMessage(message.caption)"></p>
          <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-1"><span
              class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span></small>
          } @else {
          <div class="flex flex-col items-start">
            <p class="line-through max-w-[600px] break-words" [ngClass]="{'message-text-automated': message.automated}"
              [innerHTML]="formatWhatsappMessage('Documento removido')">
            </p>
            <small class="small-message flex items-end justify-end text-[10px] pt-1"
              [ngClass]="{'small-message-deleted': message.removed}">
              <span class="timestamp">{{ message.updatedAt | date : 'dd/MM/yyyy HH:mm' }}</span>
            </small>
          </div>
          }
        </div>

        <!-- @TODO: Message of type audio -->
        <div *ngIf="message.type === 'audio'" class="w-full flex flex-col">
          @if (!message.removed) {
          @if ((env !== 'prod') && ((attendance?.channel.type === 'EVOLUTION_API' || attendance?.channel.type ===
          'EVOLUTION_GO_API') && !message.automated) &&
          ((isAttendant() && getCurrentUserUser().deleteMessage) || isAdmin() || isSupervisor())) {
          <button (click)="deleteMessageForEveryone(message)" class="h-[20px] w-full flex items-center justify-end">
            <mat-icon class="text-[#4213F6] text-[1rem]">delete</mat-icon>
          </button>
          }
          <div class="relative max-w-[630px] rounded-lg overflow-hidden shadow-lg bg-[#F1F3F4]">
            <audio [src]="message.content" controls>
              <p>Seu navegador não tem suporte para audio</p>
            </audio>
          </div>
          <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-2"><span
              class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span></small>

          } @else {
          <div class="flex flex-col items-start">
            <p class="line-through max-w-[600px] break-words" [ngClass]="{'message-text-automated': message.automated}"
              [innerHTML]="formatWhatsappMessage('Áudio removido')">
            </p>
            <small class="small-message flex items-end justify-end text-[10px] pt-1"
              [ngClass]="{'small-message-deleted': message.removed}">
              <span class="timestamp">{{ message.updatedAt | date : 'dd/MM/yyyy HH:mm' }}</span>
            </small>
          </div>
          }
        </div>
      </div>
      <chat-message-reactions-component [message]="message"></chat-message-reactions-component>
    </div>
  </div>
  }
  }

</div>