
import { NgModule } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SharedModule } from 'lib-trend-core';
import { WaTemplateModule } from '../wa-template/wa-template.module';
import { ChatMessageReactionsComponent } from './chat-messages/chat-message-reactions/chat-message-reactions.component';
import { ChatMessageReplyComponent } from './chat-messages/chat-message-reply/chat-message-reply.component';
import { ChatMessageComponent } from './chat-messages/chat-messages.component';
import { ChatSelectTemplateComponent } from './chat-select-template/chat-select-template.component';
import { ChatMessageScheduleComponent } from './chat-messages/chat-message-schedule/chat-message-schedule';
import { ChatMessageScheduleListComponent } from './chat-messages/chat-message-schedule/chat-message-schedule-list/chat-message-schedule-list';

@NgModule({
  declarations: [
    ChatMessageComponent,
    ChatSelectTemplateComponent,
    ChatMessageReactionsComponent,
    ChatMessageReplyComponent,
    ChatMessageScheduleComponent,
    ChatMessageScheduleListComponent
  ],
  exports: [
    ChatMessageComponent,
    ChatSelectTemplateComponent,
    ChatMessageReactionsComponent,
    ChatMessageReplyComponent,
  ],
  imports: [
    SharedModule,
    WaTemplateModule,
    PickerModule,
  ],
  providers: [],
})
export class ChatModule { }
