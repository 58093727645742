<div class="contact-info-main">
  <div class="contact-info">
    <img [src]="getContactImage(attendance?.contact)" class="img-user" alt="" (error)="onImageError($event)">
    <div class="w-full">
      <h4>{{ attendance?.contact.name | truncate: 10 }}</h4>
      <h6 class="sub-title">
        {{ getTranslatedStatusAttendance(attendance?.status) }}
      </h6>
    </div>
  </div>
  <button class="button-action" (click)="toggleContactInfo()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path *ngIf="contactInfoState === 'out'"
        d="M15.8337 10.8327H10.8337V15.8327H9.16699V10.8327H4.16699V9.16602H9.16699V4.16602H10.8337V9.16602H15.8337V10.8327Z"
        fill="black" />
      <path *ngIf="contactInfoState === 'in'"
        d="M15.8337 5.34102L14.6587 4.16602L10.0003 8.82435L5.34199 4.16602L4.16699 5.34102L8.82533 9.99935L4.16699 14.6577L5.34199 15.8327L10.0003 11.1743L14.6587 15.8327L15.8337 14.6577L11.1753 9.99935L15.8337 5.34102Z"
        fill="black" />
    </svg>
    <span>{{ contactInfoState === 'out' ? 'Exibir informações' : 'Fechar informações' }}</span>
  </button>
  <div [@slideInOut]="contactInfoState" class="show-contact-info">
    <!--Lead and contact-->
    <div class="flex">
      <p>Editar</p>
      <mat-icon class="text-black cursor-pointer" (click)="edit(attendance.contact)">mode_edit</mat-icon>
    </div>
    <div>
      <label class="text-sm text-gray-400">Email:</label>
      <h5 class="font-semibold text-sm">{{ attendance?.contact?.email ?? '-' }}</h5>
    </div>
    <div class="mt-[15px]">
      <label class="text-sm text-gray-400">Empresa:</label>
      <h5 class="font-semibold text-sm">{{ attendance?.contact?.contactCompany ?? '-' }}</h5>
    </div>
    <div class="mt-[15px]">
      <label class="text-sm text-gray-400">Cargo:</label>
      <h5 class="font-semibold text-sm">{{ attendance?.contact?.contactPosition ?? '-' }}</h5>
    </div>
    <div class="mt-[15px]">
      <label class="text-sm text-gray-400">Telefone:</label>
      <h5 class="font-semibold text-sm">
        <h5 class="font-semibold text-sm">
          {{ 
             (isAttendant() && !currentUser?.clientVisibilitys) || (!isAttendant() && (isSupervisor() || isAdmin()))
            ? attendance?.contact?.phone : '-' 
          }}
        </h5>
        
        
      </h5>
    </div>
    <div class="mt-[15px]">
      <label class="text-sm text-gray-400">Grupo de Contato:</label>
      <h5 class="font-semibold text-sm break-words">{{ getGroups(attendance?.contact?.groups)}}</h5>
    </div>
    <div class="mt-[15px]">
      <label class="text-sm text-gray-400">CPF:</label>
      <h5 class="font-semibold text-sm">{{ getFormatTin(attendance?.contact?.tin) }}</h5>
    </div>
    <div class="mt-[15px]">
      <label class="text-sm text-gray-400">Sexo:</label>
      <h5 class="font-semibold text-sm">{{ getFormatGender(attendance?.contact?.gender) }}</h5>
    </div>
    <div class="mt-[15px]">
      <label class="text-sm text-gray-400">Data de nascimento:</label>
      <h5 class="font-semibold text-sm">{{ (attendance?.contact?.birthDate | date: 'dd/MM/yyyy') ?? '-' }}</h5>
    </div>
    <div class="mt-[15px]">
      <label class="text-sm text-gray-400">Endereço:</label>
      <h5 class="font-semibold text-sm">{{ getFormatAddres(attendance) }}</h5>
    </div>
  </div>
</div>